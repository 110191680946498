<template>
  <div
    v-loading="loading"
    class="photo-upload"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    :style="{ width: size + 'px', height: size + 'px' }"
    :class="{
      mask: showMask,
      disabled: disabled,
      'upload-success': uploadState === 4,
      'upload-error': uploadState === 5,
    }"
  >
    <img v-if="fileUrl" :src="fileUrl" />
    <div ref="file-upload" v-else class="el-icon-plus" @click="openFile"></div>
    <i v-if="showMask" class="el-icon-delete" @click="resetFile"></i>
  </div>
</template>

<script>
import { uploadPhoto } from '@/api/loveAdvice';
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 100
    },
    value: Object
  },
  data () {
    return {
      loading: false,
      showMask: false,
      file: null,
      tempUrl: null,
      uploadState: 0 // 6个状态 0:初始状态 选择文件中：1,已选择文件：2,上传中：3,已上传:4,上传失败：5
    };
  },
  computed: {
    fileUrl () {
      return this.value ? this.value.main_url : this.tempUrl;
    }
  },
  methods: {
    onMouseEnter () {
      if (this.disabled) {
        return;
      }
      if (this.loading) {
        return;
      }
      if (this.fileUrl) {
        this.showMask = true;
      }
    },
    onMouseLeave () {
      if (this.disabled) {
        return;
      }
      if (this.showMask) {
        this.showMask = false;
      }
    },
    openFile () {
      if (this.disabled) {
        return;
      }
      const that = this;
      that.uploadState = 1;
      let parent = this.$refs['file-upload'];
      let input = document.createElement('input');
      input.style = 'display:none;';
      input.type = 'file';
      input.accept = 'image/*';
      input.addEventListener(
        'change',
        function () {
          let fileList = this.files;
          console.log(fileList);
          if (fileList.length > 0) {
            let file = fileList[0];
            that.file = file;
            let reader = new FileReader(); // 创建FileReader对象实例reader
            reader.readAsDataURL(file); // 将图片url转换为base64码
            reader.onload = function () {
              that.tempUrl = this.result;
              that.uploadState = 2;
              that.uploadFile();
              reader = null;
            };
          }
          parent.appendChild(input);
          parent.removeChild(input);
          input = null;
          parent = null;
        },
        { once: true }
      );
      input.click();
    },
    async uploadFile () {
      this.loading = true;
      this.uploadState = 3;
      try {
        const resp = await uploadPhoto(this.file);
        console.log('upload resp:', resp);
        if (resp.data.result !== 1) {
          throw new Error(resp.data.error_msg);
        }
        this.$emit('change', {
          tiny_url: resp.data.tiny_url,
          photo_id: resp.data.photo_id,
          width: resp.data.width,
          main_url: resp.data.main_url,
          large_url: resp.data.large_url,
          height: resp.data.height
        });
        this.uploadState = 4;
      } catch (error) {
        this.uploadState = 5;
        console.error(error);
        this.$message({
          type: 'error',
          message: error
        });
      }
      this.loading = false;
    },
    resetFile () {
      if (this.disabled) {
        return;
      }
      this.uploadState = 0;
      this.file = null;
      this.tempUrl = null;
      if (this.value) {
        this.$emit('change', null);
      }
      this.showMask = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.photo-upload {
  border-radius: 4px;
  border: 1px #333 dotted;
  position: relative;
  & > img,
  .el-icon-plus {
    display: block;
    width: 100%;
    height: 100%;
  }
  & > img {
    object-fit: cover;
  }
  .el-icon-plus {
    position: relative;
    &::before {
      position: absolute;
      left: 50%;
      top: 50%;
      color: #888;
      transform: translate(-50%, -50%);
      font-size: 32px;
    }
  }
  .el-icon-delete {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    &::before {
      color: #888;
      font-size: 40px;
      cursor: pointer;
    }
  }
  &.mask {
    &::after {
      content: "";
      z-index: 1;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
  &.disabled {
    cursor: not-allowed;
  }
  &.upload-success {
    $info-color: #80d402;
    border: $info-color 2px solid;
    &::before {
      background: $info-color;
      content: "上传成功";
    }
  }
  &.upload-error {
    $info-color: #ac0303;
    border: $info-color 2px solid;
    &::before {
      background: $info-color;
      content: "上传失败";
    }
  }
  &.upload-success,
  &.upload-error {
    &::before {
      position: absolute;
      color: #fff;
      opacity: 0.8;
      display: inline-flex;
      width: 100%;
      font-size: 10px;
      justify-content: center;
      bottom: 0;
    }
  }
}
</style>